<template>
    <div>
        
    <Navbar/>
    <v-container>
        <v-row align="center" justify="center">
            <v-col cols="4">
                <AutocompleteField  Label="Grupo" :GetValues="getDropdownGroups" :Items="dropdownGroups" :Value="dropdownGroup" :Fn="searchByGroup"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" align="center" justify="center">
                
                <GmapMap
                :center="{lat:21.973564405430043, lng:-101.06758061993624 }"
                :zoom="5"
                style="max-width: 1500px; max-height: 700px; margin: auto; min-width: 100px; min-height: 700px;"
                >
                    
                    <GmapMarker
                        :key="index"
                        :label="m.userName"
                        v-for="(m, index) in listOfPositions"
                        :position="m.position"
                        />
                </GmapMap>
            </v-col>
        </v-row>
    </v-container>
        
    </div>
</template>

<script>
import AutocompleteField from '../components/StandardizedComponents/autocompleteFields.vue'
import { mapState,mapActions, mapMutations } from 'vuex'
import Navbar from '../components/Navbar'
export default {
    components: {
      Navbar,
      AutocompleteField
    },
    computed:{
        ...mapState('Maps',['listOfPositions']),
        ...mapState('Groups',['dropdownGroups','dropdownGroup'])
    },
    created(){
        this.$store.dispatch('Maps/getPositions'),
        this.$store.dispatch('Groups/getDropdownGroups')
        this.$store.commit('Groups/updateOneDropdownGroup',null)
    },
    methods:{
        ...mapActions('Maps',['searchByGroup']),
        ...mapActions('Groups',['getDropdownGroups']),
        ...mapMutations('Groups',['updateOneDropdownGroup'])
    }
}
</script>

<style>

</style>