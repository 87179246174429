<template>
<div>
    <v-autocomplete v-if="GetValues"
        :value="Value"
        :rules="Rules"
        :label="Label"
        :items="Items"
        clearable
        outlined
        @click:append="GetValues()"
        @change="Fn($event)"
        @click="GetValues()"                               
    ></v-autocomplete>
    <v-autocomplete v-else
        :value="Value"
        :rules="Rules"
        :label="Label"
        :items="Items"
        clearable
        outlined
        @change="Fn($event)"                            
    ></v-autocomplete>
</div>
</template>
<script>
export default {
    props:{
        Value: undefined,          //
        Rules: Array,           //Rules for input's text
        Items: Array,           //Are the array items
        Label: String,          //Is the input's name
        Fn: Function,           //Funtion to do when the users change the input's value
        GetValues:  Function,   //Is used to get the items
    },
}
</script>